// 商品分析
import request from '@/utils/request'

// 商品分析查询初始化
export function productAnalysisPageInit() {
    return request({
      url: "/api/amazon/asin/productAnalysisPageInit",
      method: "get"
    })
}

// 商品分析查询
export function getListPage(params) {
    return request({
      url: "/api/amazon/asin/productAnalysisListPage",
      params,
      method: "get"
    })
}